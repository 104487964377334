import * as client from "../../libs/webauthn/client";
import { getCurrentUser } from "./auth";
import { webauthnServerUrl } from "./config";

const baseUrl = webauthnServerUrl;

export async function register() {
  const idToken = await getCurrentUser().getIdToken();
  const username = getCurrentUser().email;

  const challengeResponse = await fetch(`${baseUrl}/register/challenge`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${idToken}`,
    },
    body: JSON.stringify({ username }),
  });

  if (!challengeResponse.ok) {
    const json = await challengeResponse.json();
    if (json.error || json.message) {
      throw new Error(json.error || json.message);
    }
    throw new Error("Failed to get registration challenge");
  }

  const challengeText = await challengeResponse.json();

  const registration = await client.register({
    challenge: challengeText,
    user: username,
    userVerification: "preferred",
  });

  const verifyResponse = await fetch(`${baseUrl}/register/verify`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${idToken}`,
    },
    body: JSON.stringify({
      registration,
      username,
    }),
  });

  if (!verifyResponse.ok) {
    const error = await verifyResponse.text();
    throw new Error(`Registration failed: ${error}`);
  }
  return verifyResponse.json();
}

export async function getSecurityTokenDetails() {
  const idToken = await getCurrentUser().getIdToken();
  const username = getCurrentUser().email;
  try {
    const response = await fetch(`${baseUrl}/getSecurityTokenDetails`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${idToken}`,
      },
      body: JSON.stringify({
        username,
      }),
    });
    if (!response.ok) {
      return null;
    }
    const json = await response.json();
    return json;
  } catch (error) {
    console.error(error);
    return null;
  }
}

export async function authenticate() {
  const idToken = await getCurrentUser().getIdToken();
  const username = getCurrentUser().email;

  const challengeResponse = await fetch(`${baseUrl}/login/challenge`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${idToken}`,
    },
    body: JSON.stringify({ username }),
  });

  if (!challengeResponse.ok) {
    const json = await challengeResponse.json();
    if (json.error || json.message) {
      throw new Error(json.error || json.message);
    }
    throw new Error("Failed to get registration challenge");
  }

  const challengeText = (await challengeResponse.json()).challenge;

  const authentication = await client.authenticate({
    challenge: challengeText,
    timeout: 60000,
    userVerification: "preferred",
  });
  return authentication;
}

export async function verifyAuthentication(authentication) {
  const idToken = await getCurrentUser().getIdToken();
  const username = getCurrentUser().email;

  const verifyLoginResponse = await fetch(`${baseUrl}/login/verify`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${idToken}`,
    },
    body: JSON.stringify({
      authentication,
      username,
    }),
  });
  return verifyLoginResponse.json();
}

export async function appIsAllowedToReleaseWithoutWebauthn(appId: string) {
  const username = getCurrentUser().email;

  const appAllowedToReleaseWithoutWebauthn = await fetch(
    `${baseUrl}/app-allowed-to-release-without-webauthn`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ username, appId }),
    }
  );
  return (
    appAllowedToReleaseWithoutWebauthn.ok &&
    (await appAllowedToReleaseWithoutWebauthn.json()).allowed === true
  );
}
